import React, { useContext, useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import { Input } from "../components/Input";
import TagInputWithAutocomplete from "../components/TagInputWithAutocomplete";
import { useEventStream } from "../context/EventStreamingContext";
import { LinkButton, PrimaryButtonSmall } from "../components/Button";
import useImageResizer from "../utils/useImageResizer";
import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "../components/Checkbox";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  GeoPoint,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { GlobalContext } from "../context/GlobalContext";
import { fetchUsersWithIDs } from "./utils";

export const FormSection = () => {
  const { eventData, loading, setEventUsersProfile } = useEventStream();
  const { globalState } = useContext(GlobalContext);
  const { setImageForResize } = useImageResizer(600, 800);
  const [usersDb, setUsersDB] = useState([]);

  const getEventDataObject = (event) => {

    const mappedEvent = {
      id: event?.id || null,
      eventType: event?.eventType || 0,

      name: event?.name || "",
      date: event?.date ? event.date.toDate() : new Date(),
      group: event?.group || { sitters: "", movers: "" },
      size: event?.size || { sitters: 15, movers: 15 },
      photos: event?.photos || [],
      description: event?.description || "",
      hostname: event?.hostname || "",
      address: event?.address || "",
      ageRange: event?.ageRange || "",

      movers: event?.movers || [],
      sitters: event?.sitters || [],
      isActive: event?.isActive || false,
      isCompleted: event?.isCompleted || false,
      sessionTime: event?.sessionTime || 480,
      matchType: event?.eventType === 0 ? 'round-robin' : (event?.matchType || 'mutual'),
    }
    return mappedEvent
  };

  const [event, setEvent] = useState(getEventDataObject(eventData));

  useEffect(() => {
    // Update form with latest event data
    setEvent(getEventDataObject(eventData));
  }, [eventData]);

  const navigate = useNavigate();

  useEffect(() => {
    const loadUsersWithNames = async () => {
      try {
        const allUsers = await fetchUsersWithIDs(db, [
          ...event.movers,
          ...event.sitters,
        ]);
        setUsersDB(allUsers);
        setEventUsersProfile(allUsers);
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };

    loadUsersWithNames();
  }, [db, event.movers, event.sitters]); // Dependencies

  const handleChange = (field, value) => {
    setEvent((prev) => ({ ...prev, [field]: value }));
  };

  const handleNestedChange = (field, subfield, value) => {
    setEvent((prev) => ({
      ...prev,
      [field]: { ...prev[field], [subfield]: value },
    }));
  };

  const handlePhotoChange = async (e) => {
    const files = e.target.files;
    const newPhotos = await Promise.all(
      Array.from(files).map((photo) => setImageForResize(photo))
    );

    handleChange("photos", [...(event.photos || []), ...newPhotos]);
  };

  const removePhoto = (index) => {
    const updatedPhotos = event.photos.filter((_, idx) => idx !== index);
    handleChange("photos", updatedPhotos);
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Updates the event's date when the user selects a new date.
   * @param {Date} value - The new date selected by the user.
   */
  /******  72d07518-24cf-46d5-a3a0-e46584f50e9b  *******/
  const handleDateChange = (value) => {
    handleChange("date", value);
  };

  const deleteEvent = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (window.confirm("Are you sure you want to delete this event?")) {
      const docRef = doc(db, "events", eventData?.id);
      await deleteDoc(docRef)
        .then(() => alert("Event deleted successfully!"))
        .catch((error) => console.error("Error deleting document: ", error));
      navigate("/events");
    }
  };

  const mergeObjects = (original, updates) => {
    // Create a new object to store the merged result
    const merged = { ...original };

    // Function to apply updates from a single object
    const applyUpdates = (target, source) => {
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          // Check if the property is an array
          if (Array.isArray(source[key])) {
            // If update array is empty and original has values, keep original
            if (source[key].length === 0 && Array.isArray(target[key]) && target[key].length > 0) {
              continue; // Skip updating this property
            } else {
              target[key] = source[key];
            }
          } else if (typeof source[key] === 'object' && source[key] !== null) {
            // If the property is an object, recursively merge
            target[key] = mergeObjects(target[key] || {}, source[key]);
          } else {
            // Otherwise, overwrite the property
            target[key] = source[key];
          }
        }
      }
    };

    // Apply updates from the update object
    applyUpdates(merged, updates);

    return merged;
  }
  // Creating or updating an event
  const handleSubmit = async (e) => {
    e.preventDefault();


    const isValidDate = (date) => {
      const isDateObj = event.date instanceof Date;
      if (date && isDateObj) return date;
      else return new Date();
    };

    const eventDate = isValidDate(event.date);

    // Updating an existing event
    if (event.id) {
      const eventID = event.id;

      const photoURLs =
        event.photos.length > 0
          ? await Promise.all(
            event.photos.map(async (photo) => {
              if (typeof photo === "string") {
                return photo;
              }

              const uniqueName = `${uuidv4()}_${photo.name}`;

              const photoRef = ref(
                storage,
                `event/${eventID || "new"}/eventPhotos/${uniqueName}`
              );

              await uploadBytes(photoRef, photo);
              return getDownloadURL(photoRef);
            })
          )
          : [];

      const updatedEventData = {
        ...event,
        photos: photoURLs,
        date: eventDate,
        group: event.group,
      };

      let eventUsers = {};
      usersDb.forEach((u) => {
        eventUsers[u.userID] = {
          userID: u.userID,
          name: u.name,
          photoURL: u.photoURL,
          allowTextNotifications: u.allowTextNotifications || false,
          phone: u.phone || null,
          consents: [],
        };
      });

      const eventUsersArrayExists = eventData?.eventUsers && Object.keys(eventData.eventUsers).length > 0;
      if (eventUsersArrayExists) {
        eventUsers = mergeObjects(eventData.eventUsers, eventUsers);
      }
      updatedEventData["eventUsers"] = eventUsers;

      const docRef = doc(db, "events", eventID);

      await updateDoc(docRef, updatedEventData)
        .then(() => alert("Event updated successfully with photos!"))
        .catch((error) =>
          console.error("Error updating document with photos: ", error)
        );
      navigate(`/events/${docRef.id}/edit`);
    } else {
      // Creating a new event
      const eventDataForSaving = {
        ...event,
        photos: [],
        date: eventDate,
        group: event.group,
        vendorID: globalState.vendorID,
      };

      const docRef = await addDoc(collection(db, "events"), eventDataForSaving);

      const photoURLs =
        event.photos.length > 0
          ? await Promise.all(
            event.photos.map(async (photo) => {
              const photoRef = ref(
                storage,
                `event/${docRef.id}/eventPhotos/${photo.name}`
              );
              await uploadBytes(photoRef, photo);
              return getDownloadURL(photoRef);
            })
          )
          : [];

      await updateDoc(docRef, { photos: photoURLs, id: docRef.id });
      alert("Event created successfully with photos");
      navigate(`/events/${docRef.id}/edit`);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  const onEventTypeChange = (eventType) => {
    if (event.eventType === eventType.id) return;
    handleChange("eventType", eventType.id);
    if (eventType.id === 0) {
      // If event type is Round Robin, set matchType to 'round-robin'
      handleChange("matchType", "round-robin");
    } else {
      // For other event types, reset matchType to default (e.g., 'mutual')
      handleChange("matchType", 'mutual');
    }
  };
  const onMatchTypeChange = (matchType) => {
    if (event.matchType === matchType?.id) return;
    handleChange("matchType", matchType?.id);
  };
  const onSessionTimeChange = (type) => {
    if (type == undefined || event.sessionTime === type.id) return;
    handleChange("sessionTime", type.id);
  };
  const eventBtnTypes = [
    {
      id: 0,
      label: "Round Robin",
      description: "Each user will match with every other user in the group",
    },
    {
      id: 1,
      label: "Match",
      description:
        "Users will  match based on match type",
    },
  ];
  const sessionTimeBtns = [
    {
      id: 240,
      label: "4",
      description: "min",
    },

    {
      id: 360,
      label: "6",
      description: "min",
    },

    {
      id: 480,
      label: "8",
      description: "min",
    },
    {
      id: 600,
      label: "10",
      description: "min",
    },
    {
      id: 720,
      label: "12",
      description: "min",
    },
    {
      id: 900,
      label: "15",
      description: "min",
    },
  ];
  const matchBtnTypes = [
    {
      id: 'mutual',
      label: "Mutual Match",
      description: "When both users have selected each other",
    },
    {
      id: 'sitters',
      label: "Sitters Choice",
      description:
        "Sitters choose which movers they want to match with",
    },
    {
      id: 'movers',
      label: "Movers Choice",
      description:
        "Movers choose which sitters they want to match with",
    },
  ];
  const onSitterSizeChange = (v) => {
    if (event.size.sitters === v) return;
    handleNestedChange("size", "sitters", v);
  };
  const onMoverSizeChange = (v) => {
    if (event.size.movers === v) return;
    handleNestedChange("size", "movers", v);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <EventNameInput
            value={event.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />

          <EventDateInput
            selectedDate={event.date}
            onChange={handleDateChange}
          />

          <ButtonGroup
            buttons={eventBtnTypes}
            onSelected={onEventTypeChange}
            defaultSelected={event.eventType}
            label="Event Type"
          />

          {
            event.eventType === 1 && (
              <ButtonGroup
                buttons={matchBtnTypes}
                onSelected={onMatchTypeChange}
                defaultSelected={event.matchType || 'mutual'}
                label="Match Type"
              />
            )
          }
          <GroupNamesInput
            group={event.group}
            size={event.size}
            onSittersChange={(e) =>
              handleNestedChange("group", "sitters", e.target.value)
            }
            onMoversChange={(e) =>
              handleNestedChange("group", "movers", e.target.value)
            }
            onSitterSizeChange={onSitterSizeChange}
            onMoverSizeChange={onMoverSizeChange}
          />

          <ButtonGroup
            buttons={sessionTimeBtns}
            onSelected={onSessionTimeChange}
            defaultSelected={event.sessionTime}
            label="Round Length"
          />

          <PhotosInput
            photos={event.photos}
            onPhotoChange={handlePhotoChange}
            removePhoto={removePhoto}
          />

          <DescriptionInput
            value={event.description}
            onChange={(e) => handleChange("description", e.target.value)}
          />

          <HostNameInput
            value={event.hostname}
            onChange={(e) => handleChange("hostname", e.target.value)}
          />

          <AddressInput
            value={event.address}
            onChange={(e) => handleChange("address", e.target.value)}
          />

          <AgeRangeInput
            value={event.ageRange}
            onChange={(e) => handleChange("ageRange", e.target.value)}
          />


          <TagInputs
            group={event.group}
            movers={event.movers}
            sitters={event.sitters}
            usersDb={usersDb}
            onMoversChange={(newValue) => handleChange("movers", newValue)}
            onSittersChange={(newValue) => handleChange("sitters", newValue)}
          />

          <StatusCheckboxes
            isActive={event.isActive}
            isCompleted={event.isCompleted}
            onActiveChange={(e) => handleChange("isActive", e.target.checked)}
            onCompletedChange={(e) =>
              handleChange("isCompleted", e.target.checked)
            }
          />

          <FormActions
            isExistingEvent={eventData?.id}
            onDelete={deleteEvent}
            submitLabel={eventData?.id ? "Update Event" : "Create Event"}
          />
        </div>
      </form>
    </div>
  );
};

// Subcomponents

const EventNameInput = ({ value, onChange }) => (
  <Input label="Name" value={value} type="text" onChange={onChange} />
);

const EventDateInput = ({ selectedDate, onChange }) => (
  <div>
    <label htmlFor="date" className="block text-sm font-medium text-gray-700">
      Event Date & Time
    </label>
    <DatePicker
      selected={selectedDate || new Date()}
      onChange={onChange}
      showTimeSelect
      dateFormat="Pp"
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    />
  </div>
);

const GroupNamesInput = ({
  group,
  size,
  onSittersChange,
  onMoversChange,
  onSitterSizeChange,
  onMoverSizeChange,
}) => (
  <div>
    <label htmlFor="group" className="block text-sm font-medium text-gray-700">
      Group
    </label>
    <div className="mb-2 flex ">
      <Input
        label="Sitters"
        value={group.sitters}
        type="text"
        className="mr-2"
        onChange={onSittersChange}
      />
      <Input
        label="Movers"
        value={group.movers}
        type="text"
        onChange={onMoversChange}
      />
    </div>
    <label htmlFor="group" className="block text-sm font-medium text-gray-700">
      Size
    </label>
    <div className="mt-1 flex gap-4 ">
      <StepperInput onChange={onSitterSizeChange} initialValue={size.sitters} />
      <StepperInput onChange={onMoverSizeChange} initialValue={size.movers} />
    </div>
  </div>
);

const PhotosInput = ({ photos, onPhotoChange, removePhoto }) => {
  const photoPreviews = photos?.map((photo, index) => {
    const url = typeof photo === "string" ? photo : URL.createObjectURL(photo);
    return (
      <div className="photoPreviewItem" key={index}>
        <img
          src={url}
          alt={`preview-${index}`}
          className="w-24 h-24 rounded-md mr-2"
        />
        <button
          type="button"
          onClick={() => removePhoto(index)}
          className="text-red-600"
        >
          Remove
        </button>
      </div>
    );
  });

  return (
    <div>
      <label
        htmlFor="photos"
        className="block text-sm font-medium text-gray-700"
      >
        Photos
      </label>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={onPhotoChange}
        className="mt-1 block w-full text-gray-500"
      />
      <div className="flex mt-2 flex-wrap">{photoPreviews}</div>
    </div>
  );
};

const DescriptionInput = ({ value, onChange }) => (
  <div>
    <label
      htmlFor="description"
      className="block text-sm font-medium text-gray-700"
    >
      Description
    </label>
    <textarea
      id="description"
      value={value}
      onChange={onChange}
      rows={4}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryDark focus:ring-primaryDark sm:text-sm"
    ></textarea>
  </div>
);

const HostNameInput = ({ value, onChange }) => (
  <Input label="Venue Name" value={value} type="text" onChange={onChange} />
);

const AddressInput = ({ value, onChange }) => (
  <Input label="Address" value={value} type="text" onChange={onChange} />
);

const AgeRangeInput = ({ value, onChange }) => (
  <Input
    label="Age Range (e.g. 20-30)"
    value={value}
    type="text"
    onChange={onChange}
  />
);

const CoordinatesInput = ({
  coordinates,
  onLatitudeChange,
  onLongitudeChange,
}) => (
  <div>
    <Input
      label="Latitude"
      value={coordinates.latitude}
      type="text"
      onChange={onLatitudeChange}
    />
    <Input
      label="Longitude"
      value={coordinates.longitude}
      type="text"
      onChange={onLongitudeChange}
    />
  </div>
);

const TagInputs = ({
  group,
  movers,
  sitters,
  usersDb,
  onMoversChange,
  onSittersChange,
}) => (
  <div>
    <TagInputWithAutocomplete
      label={`${group?.sitters || "Sitters"}  (${sitters.length})`}
      placeholder="Add Sitters"
      value={sitters}
      onChange={onSittersChange}
      usersDb={usersDb}
      gender="Female"
      oppositeGroup={movers} // Pass movers as the opposite group
    />
    <TagInputWithAutocomplete
      label={`${group?.movers || "Movers"}  (${movers.length})`}
      placeholder="Add Movers"
      value={movers}
      onChange={onMoversChange}
      usersDb={usersDb}
      gender="Male"
      oppositeGroup={sitters} // Pass sitters as the opposite group
    />
  </div>
);

const StatusCheckboxes = ({
  isActive,
  isCompleted,
  onActiveChange,
  onCompletedChange,
}) => (
  <div className="flex justify-evenly ">
    <Checkbox label="Active" checked={isActive} onChange={onActiveChange} />
    <Checkbox
      label="Completed"
      checked={isCompleted}
      onChange={onCompletedChange}
    />
  </div>
);

const FormActions = ({ isExistingEvent, onDelete, submitLabel }) => (
  <div className="mt-6 flex items-center justify-between">
    {isExistingEvent ? (
      <LinkButton onClick={onDelete}>Delete event</LinkButton>
    ) : (
      <p></p>
    )}
    <div className="flex items-center gap-x-6">
      <PrimaryButtonSmall type="submit">{submitLabel}</PrimaryButtonSmall>
    </div>
  </div>
);

const ButtonGroup = ({
  buttons,
  onSelected = () => { },
  defaultSelected,
  label,
}) => {
  // Initialize selected state with index matching defaultSelected ID
  const [selected, setSelected] = useState(() => {
    return buttons.findIndex((btn) => btn.id === defaultSelected);
  });

  // Effect to call onSelected for the selected button on mount and when selected changes
  useEffect(() => {
    if (selected !== -1 && buttons[selected]) {
      onSelected(buttons[selected]);
    }
  }, [selected, buttons, onSelected]);

  // Update selected when defaultSelected or buttons change
  useEffect(() => {
    const selectedValue = buttons.findIndex((btn) => {
      return btn.id === defaultSelected;
    });
    setSelected(selectedValue);
  }, [defaultSelected, buttons]);

  const handleClick = (e, index) => {
    e.preventDefault();
    setSelected(index);
    onSelected(buttons[index]);
  };

  return (
    <div className="w-full ">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div
        className="grid rounded-md overflow-hidden mt-2"
        style={{ gridTemplateColumns: `repeat(${buttons.length}, 1fr)` }}
      >
        {buttons.map((btn, index, arr) => {
          const isSelected = selected === index;
          const isLast = arr.length - 1 === index;
          return (
            <button
              key={btn.id || index}
              onClick={(e) => handleClick(e, index)}
              className={`px-3 sm:px-4 py-2 ${isSelected ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                } ${!isLast && 'border-r border-slate-300'} focus:outline-none`}
            >
              <div>{btn.label}</div>
              <div className={`text-xs ${isSelected ? 'text-slate-100' : 'text-slate-400'}`}>
                {btn.description}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const StepperInput = ({
  initialValue = 15,
  step = 5,
  min = 10,
  max = 100,
  onChange,
  className = "",
  buttonClassName = "",
  inputClassName = "",
  displayClassName = "",
}) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue)) {
      setValue(Math.min(Math.max(newValue, min), max));
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const increment = (e) => {
    e.preventDefault();
    setValue((prevValue) => Math.min(prevValue + step, max));
  };

  const decrement = (e) => {
    e.preventDefault();
    setValue((prevValue) => Math.max(prevValue - step, min));
  };

  return (
    <div
      className={`flex items-center justify-center  gap-2  w-full sm:w-48 bg-white p-2 rounded-lg border shadow-sm ${className}`}
    >
      <button
        onClick={decrement}
        className={`w-8 h-8 rounded-full flex items-center justify-center focus:outline-none bg-gray-200 ${buttonClassName}`}
        disabled={value <= min}
      >
        -
      </button>
      {isEditing ? (
        <input
          ref={inputRef}
          type="number"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`w-10 lg:w-16 text-white text-md font-bold text-center bg-primary border rounded ${inputClassName}`}
          min={min}
          max={max}
        />
      ) : (
        <span
          onClick={() => setIsEditing(true)}
          className={`text-md text-white font-bold w-10 lg:w-16 text-center bg-primary py-1 rounded-lg cursor-pointer ${displayClassName}`}
        >
          {value}
        </span>
      )}
      <button
        onClick={increment}
        className={`w-8 h-8 rounded-full flex items-center justify-center focus:outline-none bg-gray-200 ${buttonClassName}`}
        disabled={value >= max}
      >
        +
      </button>
    </div>
  );
};
