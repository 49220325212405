export function createConsentMatrix(data, sitters, movers, matchType = 'mutual') {
  // Create a map for quick lookup of consents
  const consentMap = new Map(
    data
      .filter(u => u.userID && u.consents)
      .map(function (item) {
        return [item.userID, new Set(item.consents)];
      })
  );

  // Initialize the matrix with nulls
  const matrix = movers.map(function () {
    return new Array(sitters.length).fill(null);
  });

  // Fill the matrix
  movers.forEach(function (mover, moverIndex) {
    sitters.forEach(function (sitter, sitterIndex) {
      const moverConsents = consentMap.get(mover);
      const sitterConsents = consentMap.get(sitter);

      switch (matchType.toLowerCase()) {
        case 'mutual':
          // Both users must consent to each other
          if (moverConsents &&
            sitterConsents &&
            moverConsents.has(sitter) &&
            sitterConsents.has(mover)) {
            matrix[moverIndex][sitterIndex] = moverIndex;
          }
          break;

        case 'movers':
          // Only mover's consent is required
          if (moverConsents && moverConsents.has(sitter)) {
            matrix[moverIndex][sitterIndex] = moverIndex;
          }
          break;

        case 'sitters':
          // Only sitter's consent is required
          if (sitterConsents && sitterConsents.has(mover)) {
            matrix[moverIndex][sitterIndex] = moverIndex;
          }
          break;

        case 'round-robin':
          // Everyone matches with everyone
          matrix[moverIndex][sitterIndex] = moverIndex;
          break;

        default:
          throw new Error('Invalid matchType. Use "mutual", "mover", or "sitter"');
      }
    });
  });

  return { consentMatrix: matrix, sitters, movers };
}

export function matrixDiff(oldMatrix, newMatrix) {
  oldMatrix = adjustColumns(oldMatrix, newMatrix);
  const result = [];

  // Determine the number of rows to iterate through
  const maxRows = Math.max(oldMatrix.length, newMatrix.length);

  for (let i = 0; i < maxRows; i++) {
    const oldRow = oldMatrix[i] || [];
    const newRow = newMatrix[i] || [];
    const maxCols = Math.max(oldRow.length, newRow.length);

    let rowDiffers = false;
    const diffRow = new Array(maxCols).fill(null);

    for (let j = 0; j < maxCols; j++) {
      if (i >= oldMatrix.length || j >= oldRow.length) {
        // New row or column added
        diffRow[j] = newRow[j];
        rowDiffers = true;
      } else if (i >= newMatrix.length || j >= newRow.length) {
        // Row or column removed
        diffRow[j] = null;
        rowDiffers = true;
      } else if (oldRow[j] !== newRow[j]) {
        // Value changed
        diffRow[j] = newRow[j];
        rowDiffers = true;
      }
    }

    if (rowDiffers) {
      result.push(diffRow);
    }
  }

  return result;
}

export function flattenMatrix(matrix) {
  const flattenedData = {};

  matrix.forEach((row, rowIndex) => {
    row.forEach((value, colIndex) => {
      if (value !== null) {
        flattenedData[`${rowIndex}_${colIndex}`] = value;
      }
    });
  });

  return flattenedData;
}

export function reconstructMatrix(flattenedMatrix) {
  const reconstructedArray = [];

  Object.entries(flattenedMatrix).forEach(([key, value]) => {
    const [row, col] = key.split("_").map(Number);

    if (!reconstructedArray[row]) {
      reconstructedArray[row] = [];
    }

    reconstructedArray[row][col] = value;
  });

  // Fill in any missing values with null
  const maxRow = Math.max(
    ...Object.keys(flattenedMatrix).map((k) => parseInt(k.split("_")[0]))
  );
  const maxCol = Math.max(
    ...Object.keys(flattenedMatrix).map((k) => parseInt(k.split("_")[1]))
  );

  for (let i = 0; i <= maxRow; i++) {
    if (!reconstructedArray[i]) {
      reconstructedArray[i] = [];
    }
    for (let j = 0; j <= maxCol; j++) {
      if (reconstructedArray[i][j] === undefined) {
        reconstructedArray[i][j] = null;
      }
    }
  }

  return reconstructedArray;
}

export function removeUnmatchedUsers(consentData, sitters, movers, matchType = 'mutual') {
  // Helper functions for different types of matches
  const hasMutualMatch = (user1, user2) => {
    const user1Data = consentData.find(u => u.userID === user1);
    const user2Data = consentData.find(u => u.userID === user2);
    return user1Data && user2Data &&
      user1Data.consents.includes(user2) &&
      user2Data.consents.includes(user1);
  };

  const hasMoversMatch = (mover, sitter) => {
    const moverData = consentData.find(u => u.userID === mover);
    return moverData && moverData.consents.includes(sitter);
  };

  const hasSittersMatch = (sitter, mover) => {
    const sitterData = consentData.find(u => u.userID === sitter);
    return sitterData && sitterData.consents.includes(mover);
  };

  // Function to determine if there's a match based on matchType
  const hasMatch = (user1, user2, user1IsMover) => {
    switch (matchType.toLowerCase()) {
      case 'mutual':
        return hasMutualMatch(user1, user2);
      case 'movers':
        return user1IsMover ? hasMoversMatch(user1, user2) : hasMoversMatch(user2, user1);
      case 'sitters':
        return user1IsMover ? hasSittersMatch(user2, user1) : hasSittersMatch(user1, user2);
      case 'round-robin':
        return true; // Everyone matches with everyone
      default:
        throw new Error('Invalid matchType. Use "mutual", "movers", "sitters", or "all"');
    }
  };

  // If matchType is 'round-robin', return all users
  if (matchType.toLowerCase() === 'round-robin') {
    return {
      sitters: sitters,
      movers: movers
    };
  }

  // Find matches based on matchType
  const matchedSitters = sitters.filter(sitter =>
    movers.some(mover => hasMatch(sitter, mover, false))
  );

  const matchedMovers = movers.filter(mover =>
    sitters.some(sitter => hasMatch(mover, sitter, true))
  );

  return {
    sitters: matchedSitters,
    movers: matchedMovers
  };
}
export function adjustColumns(oldMatrix, newMatrix) {
  // Get the new column count
  const newColCount = newMatrix[0]?.length;

  // Map through old matrix and just extend each row
  return oldMatrix.map((row) => {
    // Create new row with existing values and pad with nulls
    const newRow = [...row];
    while (newRow.length < newColCount) {
      newRow.push(null);
    }
    return newRow;
  });
}

export function generateQueueFromConsentDeprecated(matrix) {
  const numCols = matrix[0]?.length;
  const columns = Array.from({ length: numCols }, () => []);
  const elementCounts = {};

  // Step 1: Collect elements and count occurrences
  for (let col = 0; col < numCols; col++) {
    for (let row = 0; row < matrix.length; row++) {
      const element = matrix[row][col];
      if (element !== null && element !== undefined) {
        columns[col].push(element);
        elementCounts[element] = (elementCounts[element] || 0) + 1;
      }
    }
  }

  // Step 2: Determine minimal number of rows needed
  let numRows = Math.max(...Object.values(elementCounts));

  // Step 3: Initialize data structures
  let outputMatrix = Array.from({ length: numRows }, () =>
    Array(numCols).fill(null)
  );
  const rowSets = Array.from({ length: numRows }, () => new Set());

  // Step 4: Assign elements to rows
  for (let col = 0; col < numCols; col++) {
    const elements = columns[col];
    for (const element of elements) {
      let assigned = false;

      // Try to assign to an existing row
      for (let row = 0; row < outputMatrix.length; row++) {
        if (!rowSets[row].has(element) && outputMatrix[row][col] === null) {
          outputMatrix[row][col] = element;
          rowSets[row].add(element);
          assigned = true;
          break;
        }
      }

      // If not assigned, increase the number of rows
      if (!assigned) {
        outputMatrix.push(Array(numCols).fill(null));
        rowSets.push(new Set());
        const newRow = outputMatrix.length - 1;
        outputMatrix[newRow][col] = element;
        rowSets[newRow].add(element);
      }
    }
  }

  // Step 5: Remove empty rows
  outputMatrix = outputMatrix.filter((row) =>
    row.some((cell) => cell !== null)
  );


  return outputMatrix;
}
export function generateQueueFromConsent(matrix) {
  const rows = matrix.length;
  const cols = matrix[0]?.length;
  const roundRobinQueue = generateRoundRobinQueue(matrix);
  const optimizedQueue = generateQueueForMatching(roundRobinQueue);

  if (cols > rows) {
    const distributeNullRowsEvenlyQueue = distributeNullRowsEvenly(optimizedQueue);
    return distributeNullRowsEvenlyQueue;
  }


  return optimizedQueue;
}

export function generateQueueForMatching(matrix) {
  const numCols = matrix[0]?.length;
  const columns = Array.from({ length: numCols }, () => []);
  const elementCounts = {};

  // Step 1: Collect elements and count occurrences
  for (let col = 0; col < numCols; col++) {
    for (let row = 0; row < matrix.length; row++) {
      const element = matrix[row][col];
      if (element !== null && element !== undefined) {
        columns[col].push(element);
        elementCounts[element] = (elementCounts[element] || 0) + 1;
      }
    }
  }

  // Step 2: Determine minimal number of rows needed
  let numRows = Math.max(...Object.values(elementCounts));

  // Step 3: Initialize data structures
  let outputMatrix = Array.from({ length: numRows }, () =>
    Array(numCols).fill(null)
  );
  const rowSets = Array.from({ length: numRows }, () => new Set());

  // Step 4: Assign elements to rows
  for (let col = 0; col < numCols; col++) {
    const elements = columns[col];
    for (const element of elements) {
      let assigned = false;

      // Try to assign to an existing row
      for (let row = 0; row < outputMatrix.length; row++) {
        if (!rowSets[row].has(element) && outputMatrix[row][col] === null) {
          outputMatrix[row][col] = element;
          rowSets[row].add(element);
          assigned = true;
          break;
        }
      }

      // If not assigned, increase the number of rows
      if (!assigned) {
        outputMatrix.push(Array(numCols).fill(null));
        rowSets.push(new Set());
        const newRow = outputMatrix.length - 1;
        outputMatrix[newRow][col] = element;
        rowSets[newRow].add(element);
      }
    }
  }

  // Step 5: Remove empty rows
  outputMatrix = outputMatrix.filter((row) =>
    row.some((cell) => cell !== null)
  );

  return outputMatrix;
}
function generateRoundRobinQueue(matrix) {
  const num_rows = matrix.length;
  const num_cols = matrix[0]?.length;
  const result = [];

  // Generate a Latin rectangle (num_rows x num_cols)
  for (let i = 0; i < num_rows; i++) {
    const row = [];
    for (let j = 0; j < num_cols; j++) {
      row.push((i + j) % num_rows);
    }
    result.push(row);
  }

  return removeConsentFromNullSpots(result, matrix);
}

function removeConsentFromNullSpots(latinMatrix, consentMatrix) {
  const updatedMatrix = JSON.parse(JSON.stringify(latinMatrix));

  // For each row in the consent matrix
  for (let i = 0; i < consentMatrix.length; i++) {
    const consentRowData = consentMatrix[i];
    for (let crIdx = 0; crIdx < consentRowData.length; crIdx++) {
      const isNull = consentMatrix[i][crIdx] == null;
      // If this row has a null value in the consent matrix
      if (isNull) {
        // Find where 0 appears in this row of the Latin matrix
        for (let lmIdx = 0; lmIdx < latinMatrix.length; lmIdx++) {
          const numberIndex = latinMatrix[lmIdx][crIdx] == i;
          if (numberIndex) {
            // Set that position to null
            updatedMatrix[lmIdx][crIdx] = null;
          }
        }
      }
    }
  }

  return updatedMatrix;
}


export function distributeNullRowsEvenly(matrix) {
  const rows = matrix.length;
  const cols = matrix[0]?.length;

  // Create a new matrix filled with nulls
  let result = Array(rows).fill().map(() => Array(cols).fill(null));

  // Helper function to check if a number can be placed at a position
  function isValid(row, col, num) {
    // Check row
    for (let c = 0; c < cols; c++) {
      if (result[row][c] === num) return false;
    }

    // Check column
    for (let r = 0; r < rows; r++) {
      if (result[r][col] === num) return false;
    }

    return true;
  }

  // Collect all non-null values from the original matrix
  let values = [];
  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      if (matrix[row][col] !== null) {
        values.push({
          value: matrix[row][col],
          originalCol: col
        });
      }
    }
  }

  // Sort values by original column to maintain some original structure
  values.sort((a, b) => a.originalCol - b.originalCol);

  // Try to place each value
  function placeValues(index) {
    if (index >= values.length) return true;

    const value = values[index].value;
    const preferredCol = values[index].originalCol;

    // Try each position in the matrix
    for (let col = 0; col < cols; col++) {
      // Prioritize the original column
      const actualCol = (col + preferredCol) % cols;

      for (let row = 0; row < rows; row++) {
        // Skip if we want to maintain diagonal nulls
        if (row === actualCol) continue;

        if (result[row][actualCol] === null && isValid(row, actualCol, value)) {
          result[row][actualCol] = value;
          if (placeValues(index + 1)) return true;
          result[row][actualCol] = null;
        }
      }
    }

    return false;
  }

  // Start placing values
  placeValues(0);

  result = result.filter((row) =>
    row.some((cell) => cell !== null)
  );
  return result;
}
