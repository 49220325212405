import { fetchUsersWithIDs } from "../Events/utils";
import { db } from "../firebase";

export const generateQueue = async (movers, sitters) => {
  const usersWithData = await fetchUsersWithIDs(db, [...movers, ...sitters]);

  const group1Data = movers.map(id => usersWithData.find(u => u.userID == id));
  const group2Data = sitters.map(id => usersWithData.find(u => u.userID == id));

  const BREAK = null;
  let largerGroup, smallerGroup;

  if (movers.length > sitters.length) {
    largerGroup = movers;
    smallerGroup = sitters;
  } else {
    largerGroup = sitters;
    smallerGroup = movers;
  }


  const difference = largerGroup.length - smallerGroup.length;
  let interval = smallerGroup.length / (difference + 1);

  const insertAtIndex = (arr, index, item) => {
    arr.splice(index, 0, item);
  };

  // Insert dashes in the smaller group at calculated intervals
  if (interval >= 1) {
    let insertCount = 0;
    for (let i = 1; i <= difference; i++) {
      let insertPos = i * interval + insertCount;
      insertAtIndex(smallerGroup, insertPos, BREAK);
      if (i == difference) {
        insertAtIndex(largerGroup, insertPos, BREAK);
      }
      insertCount++;
    }
  } else {
    //when its less then half ratio

    const modulo = Math.round(1 / interval) + 1;
    let insertCount = 0;
    const largerGroupCount = largerGroup.length - 1;
    for (let i = 1; i <= largerGroupCount; i++) {
      const remainder = i % modulo;

      if (remainder != 0) {
        insertAtIndex(smallerGroup, i, BREAK);

        insertCount++;
        if (insertCount == difference - 1) {
          insertAtIndex(largerGroup, i, BREAK);
        }
      }

      const isLast = i == largerGroupCount;
      if (isLast) {
        if (smallerGroup.length < largerGroup.length) {
          insertAtIndex(smallerGroup, i, BREAK);
        }
      }
    }
  }

  const mappedGroup1 = movers.map((userID, index) => {
    const user = group1Data.find((user) => user.userID == userID);
    return user ? mapUserData(user) : null;
  });

  const mappedGroup2 = sitters.map((userID, index) => {
    const user = group2Data.find((user) => user.userID == userID);
    let tableNumber = sitters.filter((id) => id != null).indexOf(userID) + 1;
    return user ? mapUserData(user, tableNumber) : null;
  });
  return {
    movers: mappedGroup1,
    sitters: mappedGroup2,
  };
};

const mapUserData = (user, tableNumber = null) => ({
  name: user.name || null,
  bio: user.bio || null,
  gender: user.gender || null,
  photoURL: user.photoURL || null,
  userID: user.userID || null,
  phone: user.phone || null,
  allowTextNotifications: user.allowTextNotifications || false,
  ...(tableNumber !== null && { table: tableNumber }),
});

const mapUsersData = (users, startTable = null) => {
  return users.map((user, index) => {
    const tableNumber = startTable !== null ? startTable + index : null;
    return mapUserData(user, tableNumber);
  });
};

export const rotateMoversQueue = (arr, num) => {
  const rotation = (num - 1) % arr.length; // Ensure rotation is within bounds of array length
  return arr.slice(-rotation).concat(arr.slice(0, -rotation));
};

export const fillNullSpotFromFront = (queue, userIDs) => {
  const result = [...queue];
  let userIDIndex = 0;

  for (let i = 0; i < result.length; i++) {
    if (result[i] === null) {
      if (userIDIndex < userIDs.length) {
        result[i] = userIDs[userIDIndex];
        userIDIndex++;
      } else {
        break;
      }
    }
  }
  return result;
};

export const regenerateQueue = async (event, lateComers, currentRound = 1) => {
  const BREAK = null;

  const fillNullSpotFromFront = (queue, users) => {
    if (queue.length == 0) {
      queue = [null];
    }

    let userIndex = 0;
    return queue.map((user) => {
      if (user === null && userIndex < users.length) {
        return users[userIndex++];
      }
      return user;
    });
  };

  const getLastTableNumber = (queue) => {
    const lastWomen = [...queue].sort((a, b) => {
      if (a === null && b === null) return 0;
      if (a === null) return 1;
      if (b === null) return -1;

      const tableA = a.table ?? -1;
      const tableB = b.table ?? -1;

      return tableB - tableA;
    });

    return lastWomen[0]?.table ?? 0;
  };

  const addLateComersRecursively = async (
    moversQueue,
    sittersQueue,
    lateComers,
    currentRound
  ) => {
    const menCount = moversQueue.filter((user) => user !== null).length;
    const womenCount = sittersQueue.filter((user) => user !== null).length;
    // const diff = Math.abs(menCount - womenCount);
    const menNullSpot = moversQueue.filter((user) => user === null).length;
    const womenNullSpot = sittersQueue.filter((user) => user === null).length;

    const addBreaksAtProperIndex = (queue, count) => {
      const index = queue.length - currentRound + 1;
      return [
        ...queue.slice(0, index),
        ...Array(count).fill(BREAK),
        ...queue.slice(index),
      ];
    };

    const addMenAtProperIndex = (queue, users) => {
      const index = queue.length - currentRound + 1;
      return [...queue.slice(0, index), ...users, ...queue.slice(index)];
    };

    if (lateComers?.movers?.length > 0) {
      // We need at least 1 null spot for a break
      if (menNullSpot > 1) {
        const insertableUserIDs = lateComers.movers.slice(0, menNullSpot - 1);
        let insertableUsersWithData = await fetchUsersWithIDs(
          db,
          insertableUserIDs
        );

        insertableUsersWithData = mapUsersData(insertableUsersWithData);

        const newMenQueue = fillNullSpotFromFront(
          moversQueue,
          insertableUsersWithData
        );
        const remainingLateComers = {
          ...lateComers,
          movers: lateComers.movers.slice(menNullSpot - 1),
        };
        return addLateComersRecursively(
          newMenQueue,
          sittersQueue,
          remainingLateComers,
          currentRound
        );
      } else {
        const remainingLateComers = lateComers.movers;
        let insertableUsersWithData = await fetchUsersWithIDs(
          db,
          remainingLateComers
        );

        insertableUsersWithData = mapUsersData(insertableUsersWithData);
        const newMenQueue = addMenAtProperIndex(
          moversQueue,
          insertableUsersWithData
        );

        const newWomenQueue = [
          ...sittersQueue,
          ...Array(remainingLateComers.length).fill(BREAK),
        ];
        const updatedLateComers = {
          ...lateComers,
          movers: [],
        };

        return addLateComersRecursively(
          newMenQueue,
          newWomenQueue,
          updatedLateComers,
          currentRound
        );
      }
    }

    if (lateComers?.sitters?.length > 0) {
      if (womenNullSpot > 1) {
        const insertableUserIDs = lateComers.sitters.slice(0, womenNullSpot - 1);
        let insertableUsersWithData = await fetchUsersWithIDs(
          db,
          insertableUserIDs
        );

        insertableUsersWithData = mapUsersData(
          insertableUsersWithData,
          getLastTableNumber(sittersQueue) + 1
        );

        const newWomenQueue = fillNullSpotFromFront(
          sittersQueue,
          insertableUsersWithData
        );
        const remainingLateComers = {
          ...lateComers,
          sitters: lateComers.sitters.slice(womenNullSpot - 1),
        };
        return addLateComersRecursively(
          moversQueue,
          newWomenQueue,
          remainingLateComers,
          currentRound
        );
      } else {
        const remainingLateComers = lateComers.sitters;
        let insertableUsersWithData = await fetchUsersWithIDs(
          db,
          remainingLateComers
        );

        insertableUsersWithData = mapUsersData(
          insertableUsersWithData,
          getLastTableNumber(sittersQueue) + 1
        );

        const newWomenQueue = [...sittersQueue, ...insertableUsersWithData];
        const newMenQueue = addBreaksAtProperIndex(
          moversQueue,
          remainingLateComers.length
        );
        const updatedLateComers = {
          ...lateComers,
          sitters: [],
        };
        return addLateComersRecursively(
          newMenQueue,
          newWomenQueue,
          updatedLateComers,
          currentRound
        );
      }
    }

    return { movers: moversQueue, sitters: sittersQueue };
  };

  const addBreakIfMissing = (moversQueue, sittersQueue) => {
    const menHasBreak = moversQueue.includes(BREAK);
    const womenHasBreak = sittersQueue.includes(BREAK);

    if (!menHasBreak) moversQueue.push(BREAK);
    if (!womenHasBreak) sittersQueue.push(BREAK);

    return { movers: moversQueue, sitters: sittersQueue };
  };

  const result = await addLateComersRecursively(
    event.queue.movers,
    event.queue.sitters,
    lateComers,
    currentRound
  );

  // Ensure there is a break at the end of the queue
  return addBreakIfMissing(result.movers, result.sitters);
};

export const removeRemovedUsersFromQueue = (queue, removedUsers) => {
  // Helper function to remove users from a specific gender queue
  const removeUsers = (genderQueue, removedGenderUsers) => {
    const removedUserIDs = new Set(
      removedGenderUsers.map((user) => user.userID)
    );
    return genderQueue.map((user) =>
      user && removedUserIDs.has(user.userID) ? null : user
    );
  };

  // Remove users from movers queue
  const updatedMenQueue = removeUsers(queue.movers, removedUsers.movers || []);

  // Remove users from sitters queue
  const updatedWomenQueue = removeUsers(queue.sitters, removedUsers.sitters || []);

  return {
    movers: updatedMenQueue,
    sitters: updatedWomenQueue,
  };
};

export const getRemovedUsers = (movers, sitters, moversQueue, sittersQueue) => {
  const removedMen = moversQueue.filter(
    (user) => user && user != null && !movers.includes(user)
  );

  const removedWomen = sittersQueue.filter(
    (user) => user && user != null && !sitters.includes(user)
  );

  return {
    movers: removedMen,
    sitters: removedWomen,
  };
};

export const getAddedUsers = (movers, sitters, moversQueue, sittersQueue) => {
  const existingMenIDs = new Set(
    moversQueue.filter((user) => user && user).map((user) => user)
  );
  const existingWomenIDs = new Set(
    sittersQueue.filter((user) => user && user).map((user) => user)
  );

  const addedMen = movers.filter((userID) => !existingMenIDs.has(userID));
  const addedWomen = sitters.filter((userID) => !existingWomenIDs.has(userID));

  return {
    movers: addedMen,
    sitters: addedWomen,
  };
};
