import * as createMatrix from "./createMatrix";
import { serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

export const generateMutualMatchQueue = async (movers, sitters, eventData) => {
  const eventUsers = Object.values(eventData.eventUsers);
  const { matchType } = eventData
  const { consentMatrix, queueMatrix, matchedAttendees } = createMatrices(eventUsers, sitters, movers, matchType);



  return {
    queueMatrix,
    consentMatrix,
    movers: matchedAttendees.movers,
    sitters: matchedAttendees.sitters,
    at: serverTimestamp()
  };
};


export const regenerateMutualMatchQueue = async (event, currentRound = 0, eventData) => {
  const eventUsers = Object.values(eventData.eventUsers);
  const { matchType } = eventData
  // Reconstruct the old matrices from the event's queue
  const oldConsentMatrix = createMatrix.reconstructMatrix(event.queue.consentMatrix);
  const oldQueueMatrix = createMatrix.reconstructMatrix(event.queue.queueMatrix);

  const removeUnmatchedUsers = createMatrix.removeUnmatchedUsers(
    eventUsers,
    event.sitters,
    event.movers,
    matchType
  )

  const incomingSitters = removeUnmatchedUsers.sitters.filter(uid => !event.queue.sitters.includes(uid))
  const incomingMovers = removeUnmatchedUsers.movers.filter(uid => !event.queue.movers.includes(uid))

  const newSitters = [...event.queue.sitters, ...incomingSitters]
  const newMovers = [...event.queue.movers, ...incomingMovers]

  // Create new consent data based on updated eventUsers, sitters, and movers
  const newConsentData = createMatrix.createConsentMatrix(eventUsers, newSitters, newMovers, matchType);

  // Compute the difference between old and new consent matrices
  const newConsentMatrixDiff = createMatrix.matrixDiff(oldConsentMatrix, newConsentData.consentMatrix);

  if (newConsentMatrixDiff.length > 0) {

    // Remove duplicates to get the new queue differences
    const newMatrixDiffQueue = createMatrix.generateQueueForMatching(newConsentMatrixDiff);
    // Adjust columns of the old queue matrix based on the adjusted round
    const slicedTopOldQueueMatrix = createMatrix.adjustColumns(
      oldQueueMatrix.slice(0, currentRound),
      newConsentData.consentMatrix
    );

    const slicedBottomOldQueueMatrix = createMatrix.adjustColumns(oldQueueMatrix.slice(currentRound), newConsentData.consentMatrix);
    // Combine the adjusted old matrix with the new differences
    const combinedMatrix = [
      ...slicedBottomOldQueueMatrix,
      ...newMatrixDiffQueue
    ];

    // Remove duplicates from the combined matrix to form the new queue matrix
    const newQueueMatrix = createMatrix.generateQueueForMatching(combinedMatrix);

    let distributeNullEvenly = newQueueMatrix;
    if (newSitters.length > newMovers.length) {
      distributeNullEvenly = createMatrix.distributeNullRowsEvenly(newQueueMatrix);
    }


    // Merge the top part of the old matrix with the new queue matrix
    const mergedQueueMatrix = [
      ...slicedTopOldQueueMatrix,
      ...distributeNullEvenly
    ];



    // Flatten the merged queue matrix for further processing
    const queueMatrix = createMatrix.flattenMatrix(mergedQueueMatrix);

    // Flatten the new consent matrix
    const consentMatrix = createMatrix.flattenMatrix(newConsentData.consentMatrix);

    return {
      queueMatrix,
      consentMatrix,
      movers: newConsentData.movers,
      sitters: newConsentData.sitters,
      at: serverTimestamp()
    };
  } else {
    throw new Error("No new changes found to regenerate queue");
  }
};

//helper functions

const createMatrices = (eventUsers, sitters, movers, matchType) => {
  // Create the consent matrix based on consents, sitters, and movers
  const removeUnmatchedUsers = createMatrix.removeUnmatchedUsers(eventUsers, sitters, movers, matchType);
  const matchedAttendees = createMatrix.createConsentMatrix(eventUsers, removeUnmatchedUsers.sitters, removeUnmatchedUsers.movers, matchType);

  if (matchedAttendees.consentMatrix.length > 0) {
    // Flatten the consent matrix for easier processing
    const consentMatrix = createMatrix.flattenMatrix(matchedAttendees.consentMatrix);

    // Remove duplicates to generate queue data
    const queueData = createMatrix.generateQueueFromConsent(matchedAttendees.consentMatrix);

    // Flatten the queue data to generate the queue matrix
    const queueMatrix = createMatrix.flattenMatrix(queueData);

    return { consentMatrix, queueMatrix, matchedAttendees };
  } else {
    throw new Error("No matches found to generate queue");
  }
};

